h1, h2, h3, h4, h5, h6 {
    font-family: Lato, Helvetica, Roboto, sans-serif;
}
h2 {
    padding-top: 20px;
    font-size: 1.8em;
}

.mobile {
    display: none;
}
.desktop {
    display: block;
}

@media only screen and (max-width: 800px) {
    .mobile {
        display: block;
    }
    .desktop {
        display: none;
    }
}
